import {Header} from './Header';
import {Todos} from './Todos';
import {CreateTodo} from './CreateTodo';


function App () {
  return (
    <>
      <header>
        <Header />
      </header>
      <main>
        <Todos />
        <CreateTodo />
      </main>
    </>
  )
}

export default App;